import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
const axios = require('axios')
import Layout from '../components/layout'
import { Container, Row, Col, Collapse } from 'reactstrap'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import {
  LineChart,
  Legend,
  ResponsiveContainer,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import LinkBack from '../components/link-back'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
const moment = require('moment')

const StationTemplate = props => {
  const [data, setData] = useState(null)
  const [site, setSite] = useState(null)
  const [dataObj, setDataObj] = useState(null)
  const [noData, setNoData] = useState(false)
  const [sitesIndex, setSitesIndex] = useState(null)
  const [index, setIndex] = useState(null)
  const [accordianItemsOn, setAccordianItemsOn] = useState([]);
  let path = props.location.pathname
  if (path[path.length - 1] === '/') {
    path = path.slice(0, -1)
  }

  const urlChunks = path.split('/')

  const code = urlChunks[urlChunks.length - 1]
  const today = moment().format('YYYY-MM-DD')
  const yesterday = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD')
  const lastYear = moment()
    .subtract(1, 'year')
    .format('YYYY-MM-DD')

  const siteName = props.pathContext.name

  useEffect(() => {
    axios(
      `https://cleanairgm.com/.netlify/functions/getSiteDetail?code=${code}&yesterday=${yesterday}&today=${today}`
    ).then(response => {
      if (response.status === 200) {
        let dataNo = []
        let dataPm = []
        let dataPm25 = []

        let maxNo = 0
        let maxPm = 0
        let maxPm25 = 0
        console.log(response)
        if (response.data === 'no data') {
          setNoData(true)
          return
        }
        response.data.map(d => {
          for (let index = 0; index < d.length; index++) {
            if (d[index].Species === 'NO2') {
              if (parseFloat(d[index].Value) > maxNo) {
                maxNo = parseFloat(d[index].Value)
              }

              let objNo = {
                name: d[index].MeasurementDate,
                value: d[index].Value,
              }

              dataNo.push(objNo)
            }

            if (d[index].Species === 'PM10') {
              if (parseFloat(d[index].Value) > maxPm) {
                maxPm = parseFloat(d[index].Value)
              }

              let objPm = {
                name: d[index].MeasurementDate,
                value: d[index].Value,
              }

              dataPm.push(objPm)
            }

            if (d[index].Species === 'PM25') {
              if (parseFloat(d[index].Value) > maxPm25) {
                maxPm25 = parseFloat(d[index].Value)
              }

              let objPm = {
                name: d[index].MeasurementDate,
                value: d[index].Value,
              }

              dataPm25.push(objPm)
            }
          }
        })
        setData(response.data)
        console.log({
          dataNo,
          dataPm,
          dataPm25,
          maxPm: Math.ceil(maxPm / 10) * 10,
          maxNo: Math.ceil(maxNo / 10) * 10,
          maxPm25: Math.ceil(maxPm25 / 10) * 10,
        })
        setDataObj({
          dataNo,
          dataPm,
          dataPm25,
          maxPm: Math.ceil(maxPm / 10) * 10,
          maxNo: Math.ceil(maxNo / 10) * 10,
          maxPm25: Math.ceil(maxPm25 / 10) * 10,
        })

        console.log(dataPm)
      }
    })
  }, [])

  useEffect(() => {
    axios(`https://cleanairgm.com/.netlify/functions/getSites`).then(
      response => {
        let st = response.data.find(s => s.SiteCode === code)
        setSite(st)
      }
    )

    axios(`https://cleanairgm.com/.netlify/functions/getSitesIndex`).then(
      response => {
        setSitesIndex(response.data)
      }
    )
  }, [])

  useEffect(() => {
    if (site != null && sitesIndex !== null && !index) {
      sitesIndex.forEach(si => {
        let key = Object.keys(si)[0]

        if (site.SiteCode === si[key].SiteCode) {
          setIndex(si[key].Value)
        }
      })
    }
  }, [site, sitesIndex])

  const toggleAccordian = (item) => {

    let items = JSON.parse(JSON.stringify(accordianItemsOn));

    if (items.includes(item)) {
      items = items.filter(f => f !== item)
    } else {
      items.push(item);
    }

    setAccordianItemsOn(items);

  }

  return (
    <Layout location={props.location}>
      <div style={{ background: '#fff' }}>
        <Helmet
          title={`${siteName} monitoring station | Clean Air Greater Manchester`}
        />
        <div className="page-banner page-banner--disclaimer">
          <img className="d-none d-lg-block" src={header} alt="" />
          <img className="d-block d-lg-none" src={headerMobile} alt="" />
          <h1>Monitoring Station: {siteName}</h1>
        </div>
        <Container>
          <LinkBack
            to={'/data-hub/monitoring-stations'}
            title={'back to monitoring stations'}
          />
        </Container>
        {noData && (
          <div className="data-hub">
            <Container className="news mt-5 mb-5">
              <h2 className="mb-2">Site information</h2>
              <p>
                We are currently not receiving any data for this station, please
                check again soon.
              </p>
            </Container>
          </div>
        )}
        {dataObj && site && !noData && (
          <div className="data-hub">
            <Container className="news mt-5 mb-5">
              <h2 className="mb-5">Site information</h2>
              <Row>
                <Col sm={12} lg={6}>
                  <Row>
                    <Col>
                      <h4>Air quality index</h4>
                      <p>
                        <span className={`air-index c${index}`}>{index}</span>
                      </p>
                    </Col>
                    <Col>
                      <h4>Date opened</h4>
                      <p>{site.DateOpened}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Site Code</h4>
                      <p>{site.SiteCode}</p>
                    </Col>
                    <Col>
                      <h4>Local Authority</h4>
                      <p>{site.LocalAuthority}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Location</h4>
                      <p>
                        {site.Latitude}, {site.Longitude}
                      </p>
                    </Col>
                    <Col>
                      <h4>Data Manager</h4>
                      <p>{site.DataManager}</p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} lg={{ size: 6, offset: 0 }}>
                  <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyA1yf9mWk13ntuoG5Lia0P-i3UH_igYi-A"
                  >
                    <GoogleMap
                      className="map"
                      id="example-map"
                      mapContainerStyle={{
                        height: '300px',
                        width: '100%',
                      }}
                      zoom={15}
                      center={{
                        lat: site.Latitude,
                        lng: site.Longitude,
                      }}
                    >
                      <Marker
                        position={{
                          lat: site.Latitude,
                          lng: site.Longitude,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                </Col>
              </Row>
            </Container>
            {dataObj.dataNo.length > 0 && (
              <>
                <Container className="mt-5">
                  <h2 className="mb-2">
                    Hourly data for NO<sub>2</sub> (previous 24 hours)
                  </h2>
                  <p className="mb-4">Hourly data is provisional.</p>
                  <div aria-hidden="true">
                    <ResponsiveContainer width="100%" minHeight={300}>
                      <LineChart
                        data={dataObj.dataNo}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 30,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          tickFormatter={timeStr => moment(timeStr).format('HH:mm')}
                        />
                        <YAxis
                          unit="µg/m³"
                          type="number"
                          tickFormatter={num => num.toPrecision(3)}
                          domain={[0, dataObj.maxNo || 100]}
                        />{' '}
                        <Tooltip />
                        <Line
                          strokeWidth={5}
                          type="monotone"
                          dataKey="value"
                          stroke="#5295AD"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Container>
                <Container className="pb-4">
                  <div key={`accordion2`} className={`mythbusting__item`}>
                    <button
                      style={{ backgroundColor: '#32748B' }}
                      className={`mythbusting__buttonSmall ${accordianItemsOn.includes('toggler2')
                        ? 'on'
                        : 'off'
                        }`}
                      id={`toggler2`}
                      onClick={() => {
                        toggleAccordian(`toggler2`)
                      }}
                    >
                      Show table data for NO<sub>2</sub>
                      <FontAwesomeIcon
                        className={`chevron  ${accordianItemsOn.includes('toggler2')
                          ? 'on'
                          : 'off'
                          }`}
                        icon={faChevronDown}
                      />
                    </button>
                    <Collapse
                      style={{ border: '1px solid #ccc', backgroundColor: '#fafafa' }}
                      className="p-4 p-lg-5"
                      isOpen={accordianItemsOn.includes('toggler2')}
                    >
                      <div style={{ backgroundColor: '#fff' }}
                      >
                        <table className='station'>
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>µg/m³</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataObj.dataNo.map(d => (
                              <tr>
                                <td>{moment(d.name).format('HH:mm')}</td>
                                <td>{d.value}</td>
                              </tr>
                            ))}


                          </tbody>
                        </table>

                      </div>
                    </Collapse>
                  </div>
                </Container>
              </>
            )}

            {dataObj.dataPm.length > 0 && (
              <>
                <Container className="mt-5">
                  <h2 className="mb-2">
                    Hourly data for PM<sub>10</sub> (previous 24 hours)
                  </h2>
                  <p className="mb-4">Hourly data is provisional.</p>
                  <div aria-hidden="true">

                    <ResponsiveContainer width="100%" minHeight={300}>
                      <LineChart
                        data={dataObj.dataPm}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 30,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          tickFormatter={timeStr => moment(timeStr).format('HH:mm')}
                        />
                        <YAxis
                          unit="µg/m³ (VCM)"
                          type="number"
                          tickFormatter={num => num.toPrecision(3)}
                          domain={[0, dataObj.maxPm || 100]}
                        />
                        <Tooltip />
                        <Line
                          strokeWidth={5}
                          type="monotone"
                          dataKey="value"
                          stroke="#5295AD"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Container>
                <Container className="pb-4">
                  <div key={`accordion1`} className={`mythbusting__item`}>
                    <button
                      style={{ backgroundColor: '#32748B' }}
                      className={`mythbusting__buttonSmall ${accordianItemsOn.includes('toggler1')
                        ? 'on'
                        : 'off'
                        }`}
                      id={`toggler1`}
                      onClick={() => {
                        toggleAccordian(`toggler1`)
                      }}
                    >
                      Show table data for PM<sub>10</sub>
                      <FontAwesomeIcon
                        className={`chevron  ${accordianItemsOn.includes('toggler1')
                          ? 'on'
                          : 'off'
                          }`}
                        icon={faChevronDown}
                      />
                    </button>
                    <Collapse
                      style={{ border: '1px solid #ccc', backgroundColor: '#fafafa' }}
                      className="p-4 p-lg-5"
                      isOpen={accordianItemsOn.includes('toggler1')}
                    >
                      <div style={{ backgroundColor: '#fff' }}
                      >
                        <table className='station'>
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>µg/m³(VCM)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataObj.dataPm.map(d => (
                              <tr>
                                <td>{moment(d.name).format('HH:mm')}</td>
                                <td>{d.value}</td>
                              </tr>
                            ))}


                          </tbody>
                        </table>

                      </div>
                    </Collapse>
                  </div>
                </Container>

              </>
            )}
            {dataObj.dataPm25.length > 0 && (
              <>
                <Container className="mt-5">
                  <h2 className="mb-2">
                    Hourly data for PM<sub>2.5</sub> (previous 24 hours)
                  </h2>
                  <p className="mb-4">Hourly data is provisional.</p>
                  <div aria-hidden="true">

                    <ResponsiveContainer width="100%" minHeight={300}>
                      <LineChart
                        data={dataObj.dataPm25}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 30,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          tickFormatter={timeStr => moment(timeStr).format('HH:mm')}
                        />
                        <YAxis
                          unit="µg/m³ (VCM)"
                          type="number"
                          tickFormatter={num => num.toPrecision(3)}
                          domain={[0, dataObj.maxPm25 || 100]}
                        />{' '}
                        <Tooltip />
                        <Line
                          strokeWidth={5}
                          type="monotone"
                          dataKey="value"
                          stroke="#5295AD"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Container>
                <Container className="pb-4">
                  <div key={`accordion3`} className={`mythbusting__item`}>
                    <button
                      style={{ backgroundColor: '#32748B' }}
                      className={`mythbusting__buttonSmall ${accordianItemsOn.includes('toggler3')
                        ? 'on'
                        : 'off'
                        }`}
                      id={`toggler3`}
                      onClick={() => {
                        toggleAccordian(`toggler3`)
                      }}
                    >
                      Show table data for PM<sub>2.5</sub>
                      <FontAwesomeIcon
                        className={`chevron  ${accordianItemsOn.includes('toggler3')
                          ? 'on'
                          : 'off'
                          }`}
                        icon={faChevronDown}
                      />
                    </button>
                    <Collapse
                      style={{ border: '1px solid #ccc', backgroundColor: '#fafafa' }}
                      className="p-4 p-lg-5"
                      isOpen={accordianItemsOn.includes('toggler3')}
                    >
                      <div style={{ backgroundColor: '#fff' }}
                      >
                        <table className='station'>
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>µg/m³ (VCM)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataObj.dataPm25.map(d => (
                              <tr>
                                <td>{moment(d.name).format('HH:mm')}</td>
                                <td>{d.value}</td>
                              </tr>
                            ))}


                          </tbody>
                        </table>

                      </div>
                    </Collapse>
                  </div>
                </Container>
              </>
            )}

            <Container className="mt-5 mb-5">
              <div>
                <a target="_blank" className="btn btn-resource" href={site.SiteLink}>
                  {site.LocalAuthority} - Air Quality Monitoring Service
                </a>
              </div>
              <div>
                <a target="_blank" className="btn btn-resource" href={site.SiteLink}>
                  Download annual data
                </a>
              </div>
              <div className="mt-3 mb-3">
                <p>
                  Please note recent automatic monitoring data are provisional.
                </p>
              </div>
            </Container>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default StationTemplate
